"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.CustomerType = exports.PROMOTIONAL_ASSET_TYPE = exports.SCREENSHOT_TYPE = exports.QUOTE_TYPE = exports.CURRENCIES = exports.CurrencyRenderOrder = exports.CURRENCY = exports.Locale = void 0;
var Locale;
(function (Locale) {
    Locale["BQUK"] = "en-GB";
    Locale["TPUK"] = "en-GB";
    Locale["BDRO"] = "ro-RO";
    Locale["CAPL"] = "pl-PL";
    Locale["CAFR"] = "fr-FR";
})(Locale = exports.Locale || (exports.Locale = {}));
var CURRENCY;
(function (CURRENCY) {
    CURRENCY["GBP"] = "GBP";
    CURRENCY["PLN"] = "PLN";
    CURRENCY["EUR"] = "EUR";
    CURRENCY["RON"] = "RON";
})(CURRENCY = exports.CURRENCY || (exports.CURRENCY = {}));
var CurrencyRenderOrder;
(function (CurrencyRenderOrder) {
    CurrencyRenderOrder[CurrencyRenderOrder["SYMBOL_FIRST"] = 0] = "SYMBOL_FIRST";
    CurrencyRenderOrder[CurrencyRenderOrder["AMOUNT_FIRST"] = 1] = "AMOUNT_FIRST";
})(CurrencyRenderOrder = exports.CurrencyRenderOrder || (exports.CurrencyRenderOrder = {}));
exports.CURRENCIES = {
    GBP: {
        symbol: '£',
        renderOrder: CurrencyRenderOrder.SYMBOL_FIRST,
        spaceDelimited: false,
    },
    EUR: {
        symbol: '€',
        renderOrder: CurrencyRenderOrder.AMOUNT_FIRST,
        spaceDelimited: true,
    },
    PLN: {
        symbol: 'zł',
        renderOrder: CurrencyRenderOrder.AMOUNT_FIRST,
        spaceDelimited: true,
    },
    RON: {
        symbol: 'Lei',
        renderOrder: CurrencyRenderOrder.AMOUNT_FIRST,
        spaceDelimited: true,
    },
};
var QUOTE_TYPE;
(function (QUOTE_TYPE) {
    QUOTE_TYPE["QUOTE"] = "Quote";
    QUOTE_TYPE["ESTIMATE"] = "Estimate";
})(QUOTE_TYPE = exports.QUOTE_TYPE || (exports.QUOTE_TYPE = {}));
var SCREENSHOT_TYPE;
(function (SCREENSHOT_TYPE) {
    SCREENSHOT_TYPE["TOP_VIEW"] = "TOP_VIEW";
    SCREENSHOT_TYPE["THREE_D_VIEW"] = "THREE_D_VIEW";
    SCREENSHOT_TYPE["SIDE_VIEW"] = "SIDE_VIEW";
    SCREENSHOT_TYPE["UNSPECIFIED_VIEW"] = "UNSPECIFIED_VIEW";
})(SCREENSHOT_TYPE = exports.SCREENSHOT_TYPE || (exports.SCREENSHOT_TYPE = {}));
var PROMOTIONAL_ASSET_TYPE;
(function (PROMOTIONAL_ASSET_TYPE) {
    PROMOTIONAL_ASSET_TYPE["FINANCE"] = "Finance";
    PROMOTIONAL_ASSET_TYPE["INSTALLATION"] = "Installation";
})(PROMOTIONAL_ASSET_TYPE = exports.PROMOTIONAL_ASSET_TYPE || (exports.PROMOTIONAL_ASSET_TYPE = {}));
var CustomerType;
(function (CustomerType) {
    CustomerType["PROFESSIONAL"] = "Professional";
    CustomerType["RETAIL"] = "Retail";
    CustomerType["TRADE_POINT"] = "TradePoint";
})(CustomerType = exports.CustomerType || (exports.CustomerType = {}));
var GapiWeekDayResponse;
(function (GapiWeekDayResponse) {
    GapiWeekDayResponse[GapiWeekDayResponse["Sunday"] = 0] = "Sunday";
    GapiWeekDayResponse[GapiWeekDayResponse["Monday"] = 1] = "Monday";
    GapiWeekDayResponse[GapiWeekDayResponse["Tuesday"] = 2] = "Tuesday";
    GapiWeekDayResponse[GapiWeekDayResponse["Wednesday"] = 3] = "Wednesday";
    GapiWeekDayResponse[GapiWeekDayResponse["Thursday"] = 4] = "Thursday";
    GapiWeekDayResponse[GapiWeekDayResponse["Friday"] = 5] = "Friday";
    GapiWeekDayResponse[GapiWeekDayResponse["Saturday"] = 6] = "Saturday";
})(GapiWeekDayResponse || (GapiWeekDayResponse = {}));
