"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.OPCOS = void 0;
var OPCOS;
(function (OPCOS) {
    OPCOS["BQUK"] = "BQUK";
    OPCOS["CAFR"] = "CAFR";
    OPCOS["BDFR"] = "BDFR";
    OPCOS["CAPL"] = "CAPL";
    OPCOS["BDRO"] = "BDRO";
    OPCOS["TPUK"] = "TPUK";
    OPCOS["BQIE"] = "BQIE";
    OPCOS["BQGG"] = "BQGG";
    OPCOS["TPGG"] = "TPGG";
    OPCOS["BQJE"] = "BQJE";
    OPCOS["TPJE"] = "TPJE";
    OPCOS["TPIE"] = "TPIE";
})(OPCOS = exports.OPCOS || (exports.OPCOS = {}));
